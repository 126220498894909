import { Component } from '@angular/core';
import { MqttService } from './services/mqtt.service';
import { ApiService } from './services/api.service';
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register(); // PARA PODER HACER REFERENCIA A ELEMENTO SWIPER
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private mqttService: MqttService,
    public apiService: ApiService,
  ) { }

  ngOnInit() {
    this.mqttService.conexion();

    let apisub = this.apiService.getConfig().subscribe({
      next: (res) => {
        if (res["subastart-pantallaConexion"]) { localStorage.setItem("mostrarConexion", res["subastart-pantallaConexion"]); }
        if (res["subastart-pantallaComprador"]) { localStorage.setItem("mostrarCompradorTimer", res["subastart-pantallaComprador"]); }
        if (res["subastart-pantallaGanador"]) { localStorage.setItem("mostrarGanador", res["subastart-pantallaGanador"]); }
        if (res["subastart-pantallaPrecio"]) { localStorage.setItem("pantallaPrecio", res["subastart-pantallaPrecio"]); }
        if (res["subastart-confSuba"]) { localStorage.setItem("confSuba", res["subastart-confSuba"]); }
        if (res["subastart-intervalDays"]) { localStorage.setItem("intervalDays", res["subastart-intervalDays"]); }
        if (res["subastart-intervalPages"]) { localStorage.setItem("intervalPages", res["subastart-intervalPages"]); }
        if (res["subastart-pantallaVelSwiper"]) { localStorage.setItem("velocidadSwiper", res["subastart-pantallaVelSwiper"]); }
        if (res["subastart-intervalPagesVentas"]) { localStorage.setItem("intervalPagesVentas", res["subastart-intervalPagesVentas"]); }
        if (res["subastart-intervalPagesArribos"]) { localStorage.setItem("intervalPagesArribos", res["subastart-intervalPagesArribos"]); }
        if (res["subastart-pantallaUltimaVenta"]) { localStorage.setItem("mostrarUltimaVenta", res["subastart-pantallaUltimaVenta"]); }

        if (res["subastart-pantallaLote"]) { localStorage.setItem("mostrarLote", res["subastart-pantallaLote"]); }
        if (res["subastart-pantallaCabeceraPuerto"]) { localStorage.setItem("textoCabeceraPuerto", res["subastart-pantallaCabeceraPuerto"]); }
        
      },
      error: (error) => {
        console.log("Error en la recepcion de datos de configuracion de pantalla.", error);
        apisub.unsubscribe();
      },
      complete: () => { apisub.unsubscribe(); }
    });
  }
}
